import React from "react";
import styles from "./legal.module.scss";
import { useDomainContext } from "@hooks/useDomainContext";
import CcpaPrivacyNoticeFallback from "./fallbacks/ccpaPrivacyNoticeFallback";
function CcpaPrivacyNotice({
    domainNameAsProp,
    className,
    mainWrapperClassName,
}: {
    domainNameAsProp?: string;
    className?: string;
    mainWrapperClassName?: string;
}) {
    const {
        domain: { textBlocks, name: actualDomainName, niceName },
        category,
    } = useDomainContext();
    const categoryName = category?.name;

    const domainName = niceName ?? actualDomainName ?? domainNameAsProp;
    const domainCcpaPrivacyNotice = textBlocks?.find(
        (textBlock) =>
            textBlock.position === "custom-domain-ccpa-privacy-notice",
    )?.text;

    const categoryCcpaPrivacyNotice = textBlocks?.find(
        (textBlock) =>
            textBlock.position === "custom-category-ccpa-privacy-notice",
    )?.text;
    return domainCcpaPrivacyNotice || categoryCcpaPrivacyNotice ? (
        <section
            className={`${
                mainWrapperClassName ?? styles["ccpaMainContainer"]
            } ${className ?? ""}`}
            dangerouslySetInnerHTML={{
                __html:
                    (domainCcpaPrivacyNotice ?? categoryCcpaPrivacyNotice)
                        ?.replace(/{domainName}/g, domainName)
                        ?.replace(/{categoryName}/g, categoryName ?? "")
                        ?.replace(/{mailInfo}/g, domainName.toLowerCase()) ??
                    "",
            }}
        />
    ) : (
        <CcpaPrivacyNoticeFallback
            domainName={domainName}
            className={className}
            mainWrapperClassName={mainWrapperClassName}
        />
    );
}

export default CcpaPrivacyNotice;
